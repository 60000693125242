import { ButtonHTMLAttributes } from "react"

type ButtonProps = React.ComponentProps<"button"> & {
    large?: boolean
}

export default function PrimaryButton({
    className = "",
    disabled,
    children,
    ...props
}: ButtonProps) {
    return (
        <button
            {...props}
            className={`
                content-center items-center rounded-3xl border
                border-transparent bg-bonza-primary px-4 py-2 text-bonza-pale
                transition duration-150 ease-in-out

                active:bg-bonza-pale active:text-bonza-dark

                disabled:bg-bonza-dark-semi

                focus:outline-none focus:ring-2 focus:ring-bonza-primary
                focus:ring-offset-2

                hover:bg-bonza-pale hover:text-bonza-dark

                ${disabled && "opacity-25"}
                ${className}
            `}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

export function SecondaryButton({
    type = "button",
    className = "",
    disabled,
    children,
    ...props
}: ButtonProps) {
    return (
        <button
            {...props}
            type={type}
            className={`
                inline-flex items-center rounded-full bg-bonza-pale-t-7 px-4
                py-2 font-semibold text-bonza-pale transition duration-150
                ease-in-out

                active:bg-bonza-pale active:text-bonza-dark

                disabled:opacity-25

                focus:outline-none focus:ring-2 focus:ring-bonza-primary
                focus:ring-offset-2

                hover:bg-bonza-pale hover:text-bonza-dark

                ${disabled && "opacity-25"}
                ${className}
            `}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

export function DangerButton({
    className = "",
    disabled,
    children,
    ...props
}: ButtonProps) {
    return (
        <button
            {...props}
            className={`
                inline-flex items-center rounded-full bg-red-500 px-4 py-2
                font-semibold text-bonza-pale transition duration-150
                ease-in-out

                active:bg-red-300 active:text-bonza-dark

                disabled:opacity-25

                focus:outline-none focus:ring-2 focus:ring-red-500
                focus:ring-offset-2

                hover:bg-red-300 hover:text-bonza-dark

                ${disabled && "opacity-25"}
                ${className}
            `}
            disabled={disabled}
        >
            {children}
        </button>
    )
}
